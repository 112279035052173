import React from "react";
import classNames from "classnames";
import {
    Card,
    CardBody,
    Row,
    Col,
    Button, Modal,
    ModalBody
} from "reactstrap";

import ReactTable from "components/ReactTable.js";
import MissingRecordDetailView from "./MissingRecordDetailView";
import ACEQuestionAnswer from "./ACEQuestionAnswer";

const FacilitySubmissionTable = (props) => {
    const [modalClassic, setModalClassic] = React.useState(false);
    const [modalResponse, setModalResponse] = React.useState(false);
    const [facilityData, setfacilityData] = React.useState({});

    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };

    const toggleModalResponse = () => {
        setModalResponse(!modalResponse);
    };

    const [data, setData] = React.useState(
        props.submissionList.map((prop, key) => {
            return {
                id: key,
                state: prop.state,
                facility: prop.facility,
                completedForms: prop.completedForms,
                missingFieldCount: prop.missing,
                missingRecords: prop.missingRecords,
                formQuestionAnswers: prop.formQuestionAnswers,
                actions: (
                    <div className="actions-right">

                        <Button
                            onClick={() => {
                                toggleModalClassic();
                                let obj = data.find((o) => o.id === key);
                                setfacilityData(obj);
                            }}
                            color="info"
                            size="sm"
                            className={classNames("btn-icon btn-link like " + (prop.missingRecords ? '' : 'hidden' ))}>
                            <i className="tim-icons icon-zoom-split" />
                        </Button>{" "}

                        <Button
                            onClick={() => {
                                let obj = data.find((o) => o.id === key);
                                toggleModalResponse();
                                setfacilityData(obj);
                            }}
                            color="warning"
                            size="sm"
                            className={classNames("btn-icon btn-link like " + (prop.formQuestionAnswers ? '' : 'hidden'))}>
                            <i className="tim-icons icon-tv-2" />
                        </Button>{" "}

                    </div>
                ),
            };
        })
    );

    

    return (
        <>
            <div>
                <Col md={8} className="ml-auto mr-auto">
                    <h2 className="text-center">Facility submissions</h2>
                </Col>
                <Row className="mt-5">
                    <Col xs={12} md={12}>
                        <Card>
                            <CardBody>
                                <ReactTable
                                    data={data}
                                    filterable
                                    resizable={false}
                                    columns={[
                                        {
                                            Header: "State",
                                            accessor: "state",
                                        },
                                        {
                                            Header: "Facility",
                                            accessor: "facility",
                                        },
                                        {
                                            Header: "Completed Forms",
                                            accessor: "completedForms",
                                        },
                                        {
                                            Header: "Missing fields",
                                            accessor: "missingFieldCount",
                                        },
                                        {
                                            Header: "",
                                            accessor: "actions",
                                            sortable: false,
                                            filterable: false,
                                        },
                                    ]}
                                    defaultPageSize={20}
                                    showPaginationTop
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                />
                            </CardBody>

                        </Card>
                    </Col>
                </Row>

                <Modal isOpen={modalClassic} toggle={toggleModalClassic} scrollable={true} size="xl" style={{ top: '-30px' }}>
                    <div className="modal-header justify-content-center">
                        <button
                            aria-hidden={true}
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleModalClassic}>
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                        <h6 className="title title-up">Missing Records</h6>
                    </div>
                    <ModalBody className="text-center">
                        <MissingRecordDetailView
                            facilityName={facilityData.facility}
                            missingData={facilityData ? facilityData.missingRecords : []}
                        />
                    </ModalBody>
                    <div className="modal-footer ml-3 mb-3">
                        <Button
                            color="danger"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleModalClassic}>
                            Close
                        </Button>
                    </div>
                </Modal>

                <Modal isOpen={modalResponse} toggle={toggleModalResponse} scrollable={true} style={{ top: '-100px', maxWidth: '95%'}}>
                    <div className="modal-header justify-content-center">
                        <button
                            aria-hidden={true}
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleModalResponse}>
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                        <h6 className="title title-up">Full Records of the facility</h6>
                    </div>
                    <ModalBody className="text-center">
                        <ACEQuestionAnswer
                            facilityName={facilityData.facility}
                            responseData={facilityData ? facilityData.formQuestionAnswers : []}
                        />
                    </ModalBody>
                    <div className="modal-footer ml-3 mb-3 mt-2">
                        <Button
                            color="danger"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleModalResponse}>
                            Close
                        </Button>
                    </div>
                </Modal>

            </div>
        </>
    );
};

export default FacilitySubmissionTable;
