import { build_side_by_side_column_chart } from 'assets/js/highchartUtils';


export function BuildACEDashBoardCharts(data) {

    let chartsToReturn = {};

    //charts of state showing expected vs completed
    chartsToReturn.state_reporting_chart = getStateMonitoringDashboard(data);
    chartsToReturn.stateChartArray = getfacilityReportingSummaryCharts(data);
    return chartsToReturn;
}


function getfacilityReportingSummaryCharts(data) {

    let stateChartArray = [];
    const thestateGroup = groupedByKey(data.facilitySubmissions, 'state');

    for (const theStateItem of Object.keys(thestateGroup)) {

        let theSiteNames = thestateGroup[theStateItem].map(function (y) { return y.facility });
        let theSites = thestateGroup[theStateItem];
        let missing = []; let completedForms = [];
         
        for (const aSite of theSites) {
            missing.push(aSite.missing);
            completedForms.push(aSite.completedForms);
        }

        let series = [{
            name: 'Completed forms',
            data: completedForms,
        }, {
            name: 'Missing required fields',
            data: missing,
        }];

        stateChartArray.push({
            stateKey: theStateItem.replaceAll(" ", "").toUpperCase(),
            theChart: build_side_by_side_column_chart(theStateItem + " State Summary", "Number of items", theSiteNames, series, 400)
        });
    }
    return stateChartArray;
}

function getStateMonitoringDashboard(data) {

    const expected = [], validRecords = [], invalidRecord = [];
    const stateArray = [];
    for (const stateItem of data.statesummary) {

        stateArray.push(stateItem.state)
        expected.push(stateItem.expected);
        validRecords.push(stateItem.validRecords);
        invalidRecord.push(stateItem.invalidRecord);
    }

    let series = [
        {
            name: 'Expected',
            data: expected,
            stack: 'stanalone',
            color: 'steelblue'
        }, {
            name: 'Incomplete Records',
            data: invalidRecord,
            stack: 'compare',
            color: 'red'
        }, {
            name: 'Valid Records',
            data: validRecords,
            stack: 'compare',
            color: 'green'
        }
        //, {
        //    type: 'pie',
        //    name: 'Total',
        //    data: [ {
        //        name: 'Valid Records',
        //        y: 23,
        //        color: 'green'
        //    }, {
        //        name: 'Incomplete Records',
        //        y: 19,
        //        color: 'red'
        //    }],
        //    center: [500, 50],
        //    size: 100,
        //    showInLegend: false,
        //    dataLabels: {
        //        enabled: true
        //    }
        //}
    ];

    let state_reporting_chart = build_side_by_side_column_chart("State Reporting Rate", "Number of facilities", stateArray, series, 400);
    return state_reporting_chart
}



//function registerButton(theChart, buttonId, inverted) {
//	document.getElementById(buttonId).addEventListener('click', () => {
//		theChart.update({
//			chart: {
//				height: inverted ? '600px' : '400px',
//				inverted: inverted,
//				polar: false
//			}
//		});
//	});
//}

function groupedByKey(array_items, key) {
    return array_items.reduce(function (r, a) {
        r[a[key]] = r[a[key]] || [];
        r[a[key]].push(a);
        return r;
    }, Object.create(null));
}