import React from 'react';



function TBDQA(props) {
   
    return (
        <div className="landingPageContent">
            <div>
                <h2>Landing age for TB DQA</h2>

                Read more about us at :
                <a href="https://www.geeksforgeeks.org/about/">
                    https://www.geeksforgeeks.org/about/
                </a>
            </div>
        </div>
    );
}

export default TBDQA;