import React from 'react';
import { Button } from "reactstrap"
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


function FacilityMonitoringChart(props) {
    //window.dispatchEvent(new Event('resize'));
    const [facilityData, setfacilityData] = React.useState(props.facilityData);
    

    return (
        <div>
            {
                facilityData ?
                    facilityData.stateChartArray.map((facilityItem, index) =>
                        <div key={facilityItem.stateKey}>
                            <HighchartsReact key={index} highcharts={Highcharts} options={facilityItem.theChart} />
                            <Button color="success" className="btn-round" size="sm">column</Button>
                            <Button color="info" className="btn-simple btn-round" size="sm">bar</Button>
                        </div>
                    )
                    :
                    ""
            }
        </div>
    );
};

export default FacilityMonitoringChart;
