import React from "react";
import { Link } from "react-router-dom";
import ColoredLine from "components/ColoredLine";
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col, CardImg, Button, CardFooter,
    ListGroupItem,
    ListGroup,
    Container,
} from "reactstrap";

function LandingPageContent(props) {

    return (
        <div className="landingPageContent">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto text-center mb-5" md="8">
                        <h1 className="title">Survey Monitoring and Analytic Dashboard</h1>
                        {/*<h4 className="description">*/}
                        {/*    Select a project below to start.*/}
                        {/*</h4>*/}
                        <ColoredLine color="azure" className="" />
                    </Col>
                </Row>
                <Row>
                    <Col lg="3" md="6" className="padding-right">
                        <Card className="card-pricing card-primary">
                            <CardBody>
                                <CardTitle tag="h1">SLS</CardTitle>
                                <CardImg
                                    alt="..."
                                    src={require("assets/img/card-primary.png").default}
                                />
                                <ListGroup>
                                    <ListGroupItem>Smart Locker Survey</ListGroupItem>
                                    <ListGroupItem>5 States</ListGroupItem>
                                    <ListGroupItem>1000 Responses</ListGroupItem>
                                </ListGroup>
                                <div className="card-prices">
                                    <h3 className="text-on-front">
                                        <span>status</span>
                                    </h3>
                                    <h5 className="text-on-back">5</h5>
                                    <p className="plan">Data analysis ongoing</p>
                                </div>
                            </CardBody>
                            <CardFooter className="text-center mb-3 mt-3">
                                <Link to="/smartlocker">
                                    <Button className="btn-round btn-just-icon" color="primary">Go to Dashboard</Button>
                                </Link>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" className="no-padding">
                        <Card className="card-pricing card-success card-white">
                            <CardBody>
                                <CardTitle tag="h1">tb dqa</CardTitle>
                                <CardImg
                                    alt="..."
                                    src={require("assets/img/card-success.png").default}
                                />
                                <ListGroup>
                                    <ListGroupItem>TPT DQA with UCSF</ListGroupItem>
                                    <ListGroupItem>100 sample size expected</ListGroupItem>
                                    <ListGroupItem>Across 6 sites</ListGroupItem>
                                </ListGroup>
                                <div className="card-prices">
                                    <h3 className="text-on-front">
                                        <span>status</span>
                                    </h3>
                                    <h5 className="text-on-back">100</h5>
                                    <p className="plan">Coming soon</p>
                                </div>
                            </CardBody>
                            <CardFooter className="text-center mb-3 mt-3">
                                <Link to="#">
                                    <Button className="btn-round btn-just-icon" color="success">Coming soon
                                    </Button>
                                </Link>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" className="no-padding">
                        <Card className="card-pricing card-warning card-raised card-white">
                            <CardBody>
                                <CardTitle tag="h1">ACE</CardTitle>
                                <CardImg
                                    alt="..."
                                    src={require("assets/img/card-warning.png").default}
                                />
                                <ListGroup>
                                    <ListGroupItem>ACE Start up Assessment</ListGroupItem>
                                    <ListGroupItem>Across 3 States</ListGroupItem>
                                    <ListGroupItem>Across 91 Health facilities</ListGroupItem>
                                </ListGroup>
                                <div className="card-prices">
                                    <h3 className="text-on-front">
                                        <span>status</span>
                                    </h3>
                                    <h5 className="text-on-back">72</h5>
                                    <p className="plan">Ongoing</p>
                                </div>
                            </CardBody>
                            <CardFooter className="text-center mb-3 mt-3">
                                <Link to="/acestartup">
                                    <Button className="btn-round btn-just-icon" color="warning">
                                        Go to Dashboard
                                    </Button>
                                </Link>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" className="padding-left">
                        <Card className="card-pricing card-danger">
                            <CardBody>
                                <CardTitle tag="h1" style={{ color: 'white' }}>Protect</CardTitle>
                                <CardImg
                                    alt="..."
                                    src={require("assets/img/card-danger.png").default}
                                />
                                <ListGroup>
                                    <ListGroupItem>Nigeria PROTECT project</ListGroupItem>
                                    <ListGroupItem>Outcome/Impact evaluation on TPT</ListGroupItem>
                                    <ListGroupItem>Funded by CDC & GATES foundation</ListGroupItem>
                                </ListGroup>
                                <div className="card-prices">
                                    <h3 className="text-on-front">
                                        <span>status</span>
                                    </h3>
                                    <h5 className="text-on-back">0</h5>
                                    <p className="plan">Protocol development</p>
                                </div>
                            </CardBody>
                            <CardFooter className="text-center mb-3 mt-3">
                                <Button className="btn-round btn-just-icon" color="danger">
                                    Coming soon
                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>

            </Container>
        </div>
    );
}

export default LandingPageContent;