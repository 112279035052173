import React from 'react';
import classNames from "classnames";
import {
    Card, CardBody, CardHeader, CardTitle, Spinner, ButtonGroup, Button, Row, Col
} from "reactstrap"
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { BuildACEDashBoardCharts } from 'assets/js/AceDashboardCharts';
import FacilitySubmissionTable from "./FacilitySubmissions";
import MapChart from './MapChart';
import AuthorizedFetch from '../../services/AuthenticatedFetch';
import FacilityMonitoringChart from './FacilityMonitoringChart';


function AceStartup(props) {
    const instanceURL = `${process.env.REACT_APP_SURVEY_BASE_URL}`
    const [pageSubcategories, setPageSubcategories] = React.useState("State-Reporting-Rate-tab");
    const [assessmentData, setAssessmentData] = React.useState(null);
    const [aceDashboardCharts, setAceDashboardCharts] = React.useState({});
    const [pageproperties, setPageproperties] = React.useState({ loading: true });
    const [timer, setTimer] = React.useState(null);
    const timeoutInMinutes = 5;

    console.log("instanceURL >= " + instanceURL);

    const fetchAssessmentData = async () => {
        clearInterval(timer);
        const theData = await AuthorizedFetch(`${instanceURL}/AceStartup/GetCachedData`, 'GET', null, true);
        //const theData = await response.json();
        setAssessmentData(theData);
        console.log(theData);
    };

    React.useEffect(() => {
        fetchAssessmentData();
    }, []);

    React.useEffect(() => {
        if (assessmentData) {
            let theDashboardCharts = BuildACEDashBoardCharts(assessmentData);
            setAceDashboardCharts(theDashboardCharts);
            setPageproperties({ loading: false });

            console.log(`redrawn charts. Refreshing in ${timeoutInMinutes} minute`);

            setTimer(
                setInterval(() => {
                    fetchAssessmentData();
                    console.log(`Refreshing in ${timeoutInMinutes} mins`);
                }, timeoutInMinutes * 60000)
            );

        } else {
            console.log("loaded on mount");
        }

        return () => {
            clearInterval(timer);
        }

    }, [assessmentData, setAceDashboardCharts]);

    const handleTabClick = () => {
        //window.dispatchEvent(new Event('resize'));
    }

    return (
        <>
            <div className="landingPageContent">
                <Row>
                    <Col xs="12">
                        <Card className="card-chart">
                            <CardHeader>
                                <Row>
                                    <Col className="text-left" sm="6">
                                        <h5 className="card-category">Monitoring Dashboard (refreshing every {timeoutInMinutes} minutes) </h5>
                                        <CardTitle tag="h2"> ACE Start-up Assessment</CardTitle>
                                    </Col>
                                    <Col sm="6">
                                        <ButtonGroup
                                            className="btn-group-toggle float-right"
                                            data-toggle="buttons">
                                            <Button
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: pageSubcategories === "State-Reporting-Rate-tab",
                                                })}
                                                color="info"
                                                id="0"
                                                size="sm"
                                                onClick={() => {
                                                    setPageSubcategories("State-Reporting-Rate-tab")
                                                    handleTabClick();

                                                }}>
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    State Monitoring
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-single-02" />
                                                </span>
                                            </Button>
                                            <Button
                                                color="info"
                                                id="1"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: pageSubcategories === "facility-Reporting-Rate-tab",
                                                })}
                                                onClick={() => {
                                                    setPageSubcategories("facility-Reporting-Rate-tab");
                                                    handleTabClick();

                                                }
                                                }>
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    facility Monitoring
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-gift-2" />
                                                </span>
                                            </Button>
                                            <Button
                                                color="info"
                                                id="2"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: pageSubcategories === "completeness-monitoring-tab",
                                                })}
                                                onClick={() => setPageSubcategories("completeness-monitoring-tab")}>
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Detail Submissions
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-tap-02" />
                                                </span>
                                            </Button>
                                            <Button
                                                color="info"
                                                id="2"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: pageSubcategories === "map-tab",
                                                })}
                                                onClick={() => setPageSubcategories("map-tab")}>
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    View on Map
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-tap-02" />
                                                </span>
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <div className="">
                                    <div className={pageSubcategories === "State-Reporting-Rate-tab" ? 'show' : 'hidden'}>
                                        {
                                            pageproperties.loading ?
                                                <div style={{ marginLeft: '40%' }}>
                                                    <img
                                                        alt="..." style={{ maxWidth: '30%' }}
                                                        src={require("assets/img/loader2.gif").default}
                                                    />
                                                </div>
                                                :
                                                <div>
                                                    <HighchartsReact key={"state_reporting_chart"} highcharts={Highcharts} options={aceDashboardCharts.state_reporting_chart} />
                                                    <Button color="success" className="btn-round" size="sm">column</Button>
                                                    <Button color="info" className="btn-simple btn-round" size="sm">bar</Button>
                                                </div>

                                        }
                                    </div>
                                    <div className={pageSubcategories === "facility-Reporting-Rate-tab" ? 'show' : 'hidden'}>
                                        {
                                            pageproperties.loading ?
                                                <div style={{ marginLeft: '40%' }}>
                                                    <img
                                                        alt="..." style={{ maxWidth: '30%' }}
                                                        src={require("assets/img/loader2.gif").default}
                                                    />
                                                </div>
                                                :
                                                <FacilityMonitoringChart facilityData={aceDashboardCharts} displayTab={pageSubcategories === "facility-Reporting-Rate-tab"} />
                                        }
                                    </div>
                                    <div className={pageSubcategories === "completeness-monitoring-tab" ? 'show' : 'hidden'}>
                                        {
                                            pageproperties.loading ?
                                                <div style={{ marginLeft: '40%' }}>
                                                    <img
                                                        alt="..." style={{ maxWidth: '30%' }}
                                                        src={require("assets/img/loader2.gif").default}
                                                    />
                                                </div>
                                                :
                                                <div>
                                                    <FacilitySubmissionTable submissionList={assessmentData.facilitySubmissions} />
                                                </div>
                                        }
                                    </div>
                                    <div className={pageSubcategories === "map-tab" ? 'show' : 'hidden'}>
                                        {
                                            pageproperties.loading ?
                                                <div style={{ marginLeft: '40%' }}>
                                                    <img
                                                        alt="..." style={{ maxWidth: '30%' }}
                                                        src={require("assets/img/loader2.gif").default}
                                                    />
                                                </div>
                                                :
                                                <div>
                                                    <span>Please resize your browser if map is not loaded</span>
                                                    <MapChart mapdata={assessmentData.facilityLocations} />
                                                </div>
                                        }
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}
export default AceStartup;