import React from 'react';
import { MapContainer, Marker, Tooltip, TileLayer, useMap, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const position = [9.103122, 7.425099];

const icon = L.icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
    shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});

//const geoUrl =   "https://raw.githubusercontent.com/emadubuko/emadubuko.github.io/main/gadm40_NGA.topojson.json";

function MultipleMarkers(props) {

    let arrCoordinates = props.coordinates.map(a => a.locations);

    //const map = useMapEvents({
    //    click: () => {
    //        map.locate()
    //    },
    //    locationfound: (location) => {
    //        console.log('location found:', location)
    //    },
    //});

    //const theMap = useMap();
    //map.invalidateSize();
    return arrCoordinates.map((coordinata, index) => {
        return <Marker key={index} position={coordinata} icon={icon} riseOnHover={true} riseOffset={10}>
            <Tooltip permanent>
                <span>You are here</span>
            </Tooltip>
        </Marker>;
    });
}

function MapChart(props) {
    //window.dispatchEvent(new Event('resize'));

    //const [arrCoordinates, setarrCoordinates] = React.useState(props.mapdata);

    return (
        <MapContainer center={position} zoom={6.5} style={{ height: "100vh" }}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {
                props.mapdata.map((coordinate, index) => {
                    return <Marker key={index} position={coordinate.locations} icon={icon} riseOnHover={true} riseOffset={10}>
                        <Tooltip permanent>
                            <span>{coordinate.facility}</span>
                        </Tooltip>
                    </Marker>;
                })
            }
            {/*<MultipleMarkers coordinates={arrCoordinates} />*/}
        </MapContainer>
    );
};

export default MapChart;
