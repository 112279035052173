/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col, Row
} from "reactstrap";
import ColoredLine from "../components/ColoredLine";
import AuthService from "../services/AuthService";

const Login = (props) => {
    const [state, setState] = React.useState({});
    const [user, setUser] = React.useState({ username: "", password: "" });
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const history = useHistory();


    async function handleLogin(e) {
        e.preventDefault();
        setLoading(true);
        setMessage("");
        //form.current.validateAll();

        let userdata = await AuthService.login(user.username, user.password);
        console.log(userdata);
        if (userdata.message) {
            setLoading(false);
            setMessage(userdata.message);
        }else if(userdata) {
            history.push("/");
            window.location.reload();
        } else {
            alert(userdata);
        }
    };

    return (
        <>
            <div className="">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center mb-5 mt-5" md="8">
                            <h1 className="title">Survey Monitoring and Analytic Dashboard</h1>
                            <ColoredLine color="azure" className="" />
                        </Col>
                    </Row>

                    <Col className="ml-auto mr-auto" lg="4" md="6">
                        <Form className="form">
                            <Card className="card-login card-white">
                                <CardHeader>
                                    <img
                                        alt="..."
                                        src={require("assets/img/card-primary.png").default}
                                    />
                                    <h1 style={{ color: '#222a42', textAlign: 'center', marginBottom: '0.75rem' }}>Log in </h1>
                                </CardHeader>
                                <CardBody>
                                    <InputGroup
                                        className={classnames({
                                            "input-group-focus": state.emailFocus,
                                        })}
                                    >
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="tim-icons icon-email-85" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="user name"
                                            type="text"
                                            onFocus={(e) => setState({ ...state, emailFocus: true })}
                                            onBlur={(e) => setState({ ...state, emailFocus: false })}
                                            onChange={(e) => setUser({ ...user, username: e.target.value })}
                                        />
                                    </InputGroup>
                                    <InputGroup
                                        className={classnames({
                                            "input-group-focus": state.passFocus,
                                        })}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="tim-icons icon-lock-circle" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Password"
                                            type="password"
                                            onFocus={(e) => setState({ ...state, passFocus: true })}
                                            onBlur={(e) => setState({ ...state, passFocus: false })}
                                            onChange={(e) => setUser({ ...user, password: e.target.value })}
                                        />
                                    </InputGroup>
                                </CardBody>
                                <CardFooter>
                                    <Button
                                        block
                                        className="mb-3"
                                        color="primary"
                                        onClick={handleLogin}
                                        size="lg"
                                        disabled={loading}>
                                        {loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>Login</span>
                                    </Button>
                                </CardFooter>
                                {message && (
                                    <div className="form-group">
                                        <div className="alert" role="alert" style={{ border: '0', color: '#fd5d93'}}>
                                            {message}
                                        </div>
                                    </div>
                                )}
                            </Card>
                        </Form>
                    </Col>

                    <Col lg="12" md="12">
                        <div style={{ textAlign: 'center' }}>
                            &copy; {new Date().getFullYear()} made with{" "}
                            <i className="tim-icons icon-heart-2"></i> by{" "}
                            <a href="https://www.savannahinnovations.com" target="_blank" rel="noreferrer">
                                Savannah Health Innovations Limited</a>
                        </div>
                    </Col>
                </Container>
            </div>
        </>
    );
};

export default Login;
