/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardImg,
    CardTitle,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";
import AuthService from "../services/AuthService";
const Register = () => {
    const [state, setState] = React.useState({});
    const [userInfo, setUserInfo] = React.useState({
        firstName:"",
        lastName: "",
        userName: "",
        email: "",
        password: "",
        confirmPassword: "",
        stateAccess: "",
        roleName: "user",
        phoneNumber : "08000000"
    });
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState("");

    React.useEffect(() => {
        document.body.classList.toggle("register-page");
        return function cleanup() {
            document.body.classList.toggle("register-page");
        };
    });


    async function handleRegister(e) {
        e.preventDefault();
        setLoading(true);
        setMessage("");
        //form.current.validateAll();

        let userdata = await AuthService.register(userInfo);
        console.log(userdata);
        if (userdata.id) {
            setLoading(false);
            setMessage("successfully added");
            setUserInfo(null);

        } else if (userdata) {
            //history.push("/");
            console.log(userdata);
            setMessage("error occured");
            //window.location.reload();
        } else {
            alert(userdata);
        }
    };

    return (
        <>
            <div className="full-page register-page">
                <div className="landingPageContent">
                    <Container>
                        <Row>
                            <Col className="ml-auto" md="1"></Col>
                            <Col className="mr-auto" md="5">
                                <Card className="card-register card-white">
                                    <CardHeader>
                                        <CardImg
                                            alt="..."
                                            src={require("assets/img/card-primary.png").default}
                                        />
                                        <CardTitle tag="h4">Survey Dashboard</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Form className="form">
                                            <h1 style={{ color: '#222a42', textAlign: 'center', marginBottom: '0.75rem' }}>Add User </h1>
                                            <InputGroup
                                                className={classnames({
                                                    "input-group-focus": state.nameFocus,
                                                })}
                                            >
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="tim-icons icon-single-02" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Full Name"
                                                    type="text"
                                                    onFocus={(e) => setState({ ...state, nameFocus: true })}
                                                    onBlur={(e) => setState({ ...state, nameFocus: false })}
                                                    onChange={(e) => {
                                                        if (e.target.value && e.target.value !== "") {
                                                            const fullName = e.target.value.split(' ');
                                                            const firstName = fullName[0];
                                                            const lastName = fullName.length > 1 ? fullName[1] : "";
                                                            setUserInfo({ ...userInfo, firstName: firstName, lastName: lastName });
                                                        }                                                        
                                                    }}
                                                />
                                            </InputGroup>
                                            <InputGroup
                                                className={classnames({
                                                    "input-group-focus": state.emailFocus,
                                                })}
                                            >
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="tim-icons icon-email-85" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Email"
                                                    type="text"
                                                    onFocus={(e) =>
                                                        setState({ ...state, emailFocus: true })
                                                    }
                                                    onBlur={(e) =>
                                                        setState({ ...state, emailFocus: false })
                                                    }
                                                    onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
                                                />
                                            </InputGroup>
                                            <InputGroup
                                                className={classnames({
                                                    "input-group-focus": state.passFocus,
                                                })}
                                            >
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="tim-icons icon-lock-circle" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="User name"
                                                    type="text"
                                                    onFocus={(e) => setState({ ...state, passFocus: true })}
                                                    onBlur={(e) => setState({ ...state, passFocus: false })}
                                                    onChange={(e) => setUserInfo({ ...userInfo, userName: e.target.value })}
                                                />
                                            </InputGroup>
                                            <InputGroup
                                                className={classnames({
                                                    "input-group-focus": state.passFocus,
                                                })}
                                            >
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="tim-icons icon-lock-circle" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Password"
                                                    type="password"
                                                    onFocus={(e) => setState({ ...state, passFocus: true })}
                                                    onBlur={(e) => setState({ ...state, passFocus: false })}
                                                    onChange={(e) => setUserInfo({ ...userInfo, password: e.target.value })}
                                                />
                                            </InputGroup>
                                            <InputGroup
                                                className={classnames({
                                                    "input-group-focus": state.passFocus,
                                                })}
                                            >
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="tim-icons icon-lock-circle" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Confirm Password"
                                                    type="password"
                                                    onFocus={(e) => setState({ ...state, passFocus: true })}
                                                    onBlur={(e) => setState({ ...state, passFocus: false })}
                                                    onChange={(e) => setUserInfo({ ...userInfo, confirmPassword: e.target.value })}
                                                />
                                            </InputGroup>
                                        </Form>
                                    </CardBody>
                                    <CardFooter>
                                        <Button
                                            className="btn-round"
                                            color="primary"
                                            //href="#pablo"
                                            onClick={handleRegister}
                                            size="lg">
                                            Submit
                                        </Button>
                                    </CardFooter>

                                    {message && (
                                        <div className="form-group">
                                            <div className="alert" role="alert" style={{ border: '0', color: '#fd5d93' }}>
                                                {message}
                                            </div>
                                        </div>
                                    )}
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </>
    );
};

export default Register;
