////import React from 'react';
////import ReactDOM from 'react-dom';
////import './index.css';
////import App from './App';
////import reportWebVitals from './reportWebVitals';

////ReactDOM.render(
////  <React.StrictMode>
////    <App />
////  </React.StrictMode>,
////  document.getElementById('root')
////);

////// If you want to start measuring performance in your app, pass a function
////// to log results (for example: reportWebVitals(console.log))
////// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
////reportWebVitals();
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import LandingPage from "views/LandingPage.js";
import reportWebVitals from './reportWebVitals';

import "assets/scss/black-dashboard-react.scss";
import "assets/css/demo.css";
import 'assets/css/style-utils.css';
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import ThemeContextWrapper from "./components/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper";

ReactDOM.render(
    <ThemeContextWrapper >
        <BackgroundColorWrapper>
            <BrowserRouter>
                <LandingPage />
            </BrowserRouter>
        </BackgroundColorWrapper>
    </ThemeContextWrapper>,
    document.getElementById("root")
);

////// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();