import Highcharts from 'highcharts';


function percentage(num, per) {
    var result = num * (per / 100);
    return Math.round(result);
}

Highcharts.getOptions().colors = Highcharts.map(Highcharts.getOptions().colors, function (color) {
    return {
        radialGradient: {
            cx: 0.5,
            cy: 0.3,
            r: 0.7
        },
        stops: [
            [0, color],
            [1, Highcharts.Color(color).brighten(-0.3).get('rgb')] // darken
        ]
    };
});

Highcharts.setOptions({
    lang: {
        decimalPoint: '.',
        thousandsSep: ','
    }
});

var pieColors = (function () {
    var colors = [],
        i,
        base;
    for (i = 0; i < 50; i += 3) {
        // Start out with a darkened base color (negative brighten), and end
        // up with a much brighter color
        base = Highcharts.getOptions().colors[i];
        colors.push(Highcharts.Color(base).get());
    }
    return colors;
}());


export const BuildDonut = function (title, data_array) {

    return {
        chart: {
            type: 'pie'
        },
        title: {
            text: title,
            style: {
                fontSize: '14px'
            }
        },
        legend: {
            reversed: true
        },
        plotOptions: {
            pie: {
                colors: pieColors,
                shadow: false,
                dataLabels: {
                    format: '{point.y:,.0f}<br /> ({point.percentage:.1f} %)'
                }
            }
        },
        tooltip: {
            pointFormat: '{point.y} ({point.percentage:.1f}%)'
        },
        series: [{
            data: data_array,
            size: '80%',
            innerSize: '60%',
            showInLegend: true,

        }],
        credits: {
            enabled: false
        },
        colors: ['grey', '#F2784B', '#08bf0f'],
        // exporting: { enabled: false },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    chart: {
                        height: 300
                    },
                    subtitle: {
                        text: null
                    },
                    navigator: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    }
                }
            }]
        }
    }
}

export const BuildPieChart = function (title, data_array) {
    return {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
           // height: 300
        },
        title: {
            text: title,
            style: {
                fontSize: '14px'
            }
        },
        tooltip: {
            pointFormat: '{point.y} ({point.percentage:.1f}%)'
        },
        plotOptions: {
            pie: {
                colors: pieColors,
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    format: '{point.y:,.0f} ({point.percentage:.1f} %)'
                },
                showInLegend: true,
            }
        },
        series: [{
            data: data_array,
        }],
        credits: {
            enabled: false
        },
        //exporting: { enabled: false },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    chart: {
                        height: 300
                    },
                    subtitle: {
                        text: null
                    },
                    navigator: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    }
                }
            }]
        },
        //colors: ['grey', '#F2784B', '#08bf0f'],//'#1ba39c'
    }
    //chart.series[0].data[0].update({
    //    color: '#00a65a'
    //});
    //chart.series[0].data[1].update({
    //    color: 'sandybrown'
    //});
}

export const Build_Pos_Neg_Chart = function (title, categories, series_data, max) {

    return {
        chart: {
            type: 'bar',
            height: 300
        },
        title: {
            text: title,
            style: {
                fontSize: '12px'
            }
        },
        xAxis: [{
            categories: categories,
            reversed: true,
            labels: {
                step: 1
            }
        }, { // mirror axis on right side
            opposite: true,
            reversed: true,
            categories: categories,
            linkedTo: 0,
            labels: {
                step: 1
            }
        }],
        yAxis: {
            title: {
                text: null
            },
            labels: {
                formatter: function () {
                    return Math.abs(this.value);
                }
            },
            max: (max + percentage(max, 10)),
            min: -(max + percentage(max, 10))
        },
        colors: ['#457eb5', '#b9651e'],
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                stacking: 'normal',
            }
        },
        tooltip: {
            formatter: function () {
                return '<b>' + this.series.name + ', age ' + this.point.category + '</b><br/>' +
                    'Total: ' + Highcharts.numberFormat(Math.abs(this.point.y), 0);
            }
        },
        series: series_data
    }
}

export const build_drilldown_bar_chart = function (title, yaxistitle, principal_data, drill_down_data, addSigntovalue = "") {

    var colors = get_color_shades(2);

    return {

        chart: {
            type: 'column'
        },
        title: {
            text: title,
            style: {
                fontSize: '14px'
            }
        },
        subtitle: {
            text: 'Click the columns to drill down'
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: {
                text: yaxistitle
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                colors: colors,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y:, 1f} ' + addSigntovalue
                    //format: '{point.y:.1f}%'
                },

            }
        },

        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}' + addSigntovalue + '</b> '
        },

        series: [{
            name: ' State',
            colorByPoint: true,
            data: principal_data
        }],
        credits: {
            enabled: false
        },
        exporting: { enabled: false },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    chart: {
                        height: 300
                    },
                    subtitle: {
                        text: null
                    },
                    navigator: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    }
                }
            }]
        },
        drilldown: {
            series: drill_down_data
        }
    }
}

export const build_Column_chart = function (title, yaxistitle, xaxisCategory, data, average_value, height, sign = '') {
    
    return {
        chart: {
            type: 'column',
            height: height
        },
        title: {
            text: title,
            style: {
                fontSize: '14px'
            }
        },
        xAxis: {
            categories: xaxisCategory,
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: yaxistitle,
            },
            plotLines: [{
                color: 'red',
                value: average_value,
                width: '1',
                zIndex: 2
            }]
        },
        legend: {
            enabled: false
        },
        tooltip: {
            pointFormat: '<b>{point.y:, 1f}</b>',
        },
        //colors: colors,
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            },
            series: {
                depth: 25,
                colorByPoint: true,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y:, 1f}' + sign
                }
            }
        },
        series: [{
            data: data
        }],
        credits: {
            enabled: false
        },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 600
                },
                chartOptions: {
                    chart: {
                        height: 300
                    },
                    subtitle: {
                        text: null
                    },
                    navigator: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    }
                }
            }]
        }
    }
}

export const build_bar_chart_dual_axis = function (title, y1_title, y2_title, xaxisCategory, parent_data, parent_data_name, child_data, child_data_name, percent_data, percent_data_name, useLine = true, height) {

    return {
        chart: {
            zoomType: 'xy',
            height: height
        },
        title: {
            text: title,
            style: {
                fontSize: '12px'
            }
        },
        xAxis: [{
            categories: xaxisCategory,
            crosshair: true
        }],
        yAxis: [
            { // Secondary yAxis
                title: {
                    text: y1_title,
                    rotation: 270,
                },

                labels: {
                    format: '{value}',
                },
                max: Math.max.apply(Math, parent_data),
                min: 0
            },
            { // Primary yAxis
                labels: {
                    format: '{value} %',
                },
                title: {
                    text: y2_title,
                },
                opposite: true,
                max: 100,
                min: 0
            }],
        tooltip: {
            shared: true
        },
        colors: ['steelblue', 'sandybrown', 'green'],
        legend: {
            enabled: true,
        },
        series: [{
            name: parent_data_name,
            type: 'column',
            data: parent_data

        }, {
            name: child_data_name,
            type: 'column',
            data: child_data
        },
        {
            name: percent_data_name,
            type: useLine ? 'spline' : 'scatter',
            data: percent_data,
            yAxis: 1,
            tooltip: {
                pointFormat: '<b>{point.y:.1f}%</b>',
                //valueSuffix: ' %'
            }
        }],
        credits: {
            enabled: false
        },
        exporting: { enabled: false },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    chart: {
                        height: 300
                    },
                    subtitle: {
                        text: null
                    },
                    navigator: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    }
                }
            }]
        }
    }
}

export const build_trend_chart = function (title, yAxistitle, xaxisCategory, series_data, height) {
    //var colors = get_color_shades(2); 
    return {
        chart: {
            type: 'spline',
           // height: height
        },
        title: {
            text: title,
            style: {
                fontSize: '14px'
            }
        },

        yAxis: {
            title: {
                text: yAxistitle
            }
        },
        xAxis: {
            categories: xaxisCategory,
        },
        colors: ['#808000', '#46f0f0', '#e6194b', '#3cb44b', '#f58231', '#0082c8', '#911eb4', '#000000', '#f032e6', '#008080', '#aa6e28', '#000080', '#d2f53c'],
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
            title: {
                text: '<span style="font-size: 12px;text-decoration: underline;"> Cohorts </span>',
                style: {
                    fontStyle: 'italic'
                }
            },
            labelFormatter: function () {
                return '<span style="color:' + this.color + ';">' + this.name + '</span>';
            },
        },
        tooltip: {
            formatter: function () {
                return '<b>Report period:</b> ' + this.point.category + '<br /><b> Cohort </b>:' + this.series.name + '<br /><b> No. of patient:</b>' + this.point.y
            }
        },

        plotOptions: {
            line: {
                marker: {
                    enabled: false
                }
            }
        },

        series: series_data,
        credits: {
            enabled: false
        },
        exporting: { enabled: false },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    chart: {
                        height: 300
                    },
                    subtitle: {
                        text: null
                    },
                    navigator: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    }
                }
            }]
        }
    }
}

export const build_side_by_side_column_chart = function (title, yAxistitle, xaxisCategory, series_data) {
    return {
        chart: {
            type: 'column'
        },
        reflow : true,
        title: {
            text: title
        },
        xAxis: {
            categories: xaxisCategory,
            //crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: yAxistitle
            }
        },
        colors: ['steelblue', 'sandybrown', 'green', '#5e1b1b'],
        tooltip: {
            formatter: function () {
                return '<b>' + this.x + '</b><br/>' +
                    this.series.name + ': ' + this.y + '<br/>' +
                    'Total: ' + this.point.stackTotal;
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal'
            },
            series: {
                dataLabels: {
                    enabled: true,
                    format: '{point.y:, 1f}'
                }
            }
        },
        credits: {
            enabled: false
        },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    chart: {
                        height: 300
                    },
                    subtitle: {
                        text: null
                    },
                    navigator: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    }
                }
            }]
        },
        series: series_data
    }
}


function get_color_shades(start) {
    var colors = [],
        base = Highcharts.getOptions().colors[start],
        i;

    for (i = 0; i < 100; i += 1) {
        colors.push(Highcharts.Color(base).brighten((i) / 100).get());
    }
    return colors;
}

export const plotMapSimple = function (main_title, statedata, colorOption, legend_size, legend_spacing) {

    var mapData = Highcharts.geojson(Highcharts.maps['countries/ng/ng-all']);

    // Initiate the map chart
    return {
        chart: {
            marginRight: legend_spacing,
            margin: [0, 0, 0, 0],
            spacingTop: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0,
        },
        title: {
            text: main_title,
            style: {
                fontSize: '14px'
            }
        },
        legend: {
            title: {
                style: {
                    color: (Highcharts.theme && Highcharts.theme.textColor) || 'black'
                }
            },
            itemStyle: {
                fontSize: '15px'
            },
            align: 'right',
            verticalAlign: 'bottom',
            floating: true,
            layout: 'vertical',
            valueDecimals: 0,
            backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255, 255, 255, 0.85)',
            symbolRadius: 0,
            symbolHeight: 18
        },
        colorAxis: {
            dataClasses: colorOption
        },
        tooltip: {
            footerFormat: '<span style="font-size: 10px">(Click for details)</span>'
        },
        series: [{
            data: statedata,
            mapData: mapData,
            joinBy: ['hc-key', 'code3'],
            name: 'Nigeria',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '{point.properties.name}'
            },
            states: {
                select: {
                    color: '#a4edba',
                    borderColor: 'black',
                    dashStyle: 'shortdot'
                }
            }
        }],
        credits: {
            enabled: false
        },
        exporting: { enabled: false },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 2000
                },
                chartOptions: {
                    chart: {
                        height: 520
                    },
                    legend: {
                        itemStyle: {
                            fontSize: legend_size
                        },
                    },
                    subtitle: {
                        text: null
                    },
                    navigator: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    }
                }
            }]
        }
    }
}

export const build_stacked_bar_with_percent = function (title, xaxis_categories, y1_axis_title, y2_axis_title, smaller_data_set, smaller_data_name, larger_data_set, bigger_data_name, percent_data, useLine = true, height) {

    return {
        chart: {
            type: 'column',
            height: 320
        },
        title: {
            text: title
        },
        xAxis: {
            categories: xaxis_categories
        },
        yAxis: [{
            min: 0,
            title: {
                text: y1_axis_title
            },
        },
        {
            labels: {
                format: '{value} %',
            },
            title: {
                text: y2_axis_title,
            },
            opposite: true,
            max: 100,
            min: 0
        }
        ],
        credits: {
            enabled: false
        },
        exporting: { enabled: false },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 800
                },
                chartOptions: {
                    chart: {
                        height: 290
                    },
                    subtitle: {
                        text: null
                    },
                    navigator: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    }
                }
            }]
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true,
                }
            }
        },
        colors: ['sandybrown', 'green', 'cornflowerblue'],
        series: [{
            name: smaller_data_name,
            data: smaller_data_set
        }, {
            name: bigger_data_name,
            data: larger_data_set
        },

        {
            name: 'percentage',
            type: useLine ? 'spline' : 'scatter',
            data: percent_data,
            yAxis: 1,
        }]
    }
}


export const ExportFormattedData = function (csvFile, filename) {
    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
