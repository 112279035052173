import React from "react";
import { useLocation, Switch, Route } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import SmartLockerDashboard from "./smartlocker/SmartLockerDashboard";
import AceStartup from "views/ACEAssessment/AceStartup";
import TBDQA from "views/TBDQA";

import AdminNavbar from "components/AdminNavbar.js";
import Footer from "components/Footer.js";
//import FixedPlugin from "components/FixedPlugin.js";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";

import Login from "./Login";

import AuthService from "../services/AuthService";
import LandingPageContent from "./LandingPageContent";
import Register from "./Register";

var ps;

function LandingPage(props) {

    const [currentUser, setCurrentUser] = React.useState();
    React.useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (user) {
            setCurrentUser(user);
            //setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
        }
    }, []);
   

    const mainPanelRef = React.useRef(null);
    const location = useLocation();
    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            document.documentElement.className += " perfect-scrollbar-on";
            document.documentElement.classList.remove("perfect-scrollbar-off");
            ps = new PerfectScrollbar(mainPanelRef.current, {
                suppressScrollX: true,
            });
        }
        // Specify how to clean up after this effect:
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
                document.documentElement.classList.add("perfect-scrollbar-off");
                document.documentElement.classList.remove("perfect-scrollbar-on");
            }
        };
    });
    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        if (mainPanelRef.current) {
            mainPanelRef.current.scrollTop = 0;
        }

        console.log(location.pathname);
        setshowbackbutton(location.pathname !== "/");
    }, [location]);

    const [sidebarOpened, setsidebarOpened] = React.useState(
        document.documentElement.className.indexOf("nav-open") !== -1
    );
    const toggleSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        setsidebarOpened(!sidebarOpened);
    };

    const [showbackbutton, setshowbackbutton] = React.useState(false);

    return (

        <BackgroundColorContext.Consumer>
            {({ color, changeColor }) => (
                <React.Fragment>
                    <div className="wrapper">
                        <div className="main-panel" ref={mainPanelRef} data={color}>

                            {currentUser ? (
                                <div>
                                    <AdminNavbar
                                        brandText={"Survey Dashboard"}
                                        toggleSidebar={toggleSidebar}
                                        sidebarOpened={sidebarOpened}
                                        showbackbutton={showbackbutton}
                                        loggedUser={currentUser}
                                    />
                                    <Switch>
                                        <Route exact path="/smartlocker">
                                            <SmartLockerDashboard />
                                        </Route>
                                        <Route path="/tbdqa">
                                            <TBDQA />
                                        </Route>
                                        <Route path="/acestartup">
                                            <AceStartup />
                                        </Route>
                                        <Route path="/account/addUser">
                                            <Register />
                                        </Route>
                                        <Route exact path="/">
                                            <LandingPageContent />
                                        </Route>
                                    </Switch>
                                    <Footer fluid />
                                </div>

                            ) : (
                                <Login />
                            )}


                        </div>
                    </div>
                    {/*<FixedPlugin bgColor={color} handleBgClick={changeColor} />*/}
                </React.Fragment>
            )}
        </BackgroundColorContext.Consumer>
    );
}

export default LandingPage;