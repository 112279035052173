import { BuildDonut, build_side_by_side_column_chart, BuildPieChart, build_Column_chart, Build_Pos_Neg_Chart } from 'assets/js/highchartUtils';

export function BuildSmartLockerCharts(data, Ip_facilityDistributionData) {

    let chartsToReturn = {};
    //state
    const thestateGroup = groupedByKey(Ip_facilityDistributionData, 'state');
    let xCategory = Object.keys(thestateGroup);
    let patientResponded = [], patientRemaining = [], hcwResponded = [], hcwRemaining = [];

    for (const stateName of xCategory) {

        var patientResponded_ = getdistributionSampleCollectedGrouping('state', data.stateReportingRate, stateName, 'I am currently living with a long term/chronic illness');
        var patientResponded2_ = getdistributionSampleCollectedGrouping('state', data.stateReportingRate, stateName, 'I am a caregiver to one or more persons living with a long term/chronic illness');
        var hcwResponded_ = getdistributionSampleCollectedGrouping('state', data.stateReportingRate, stateName, 'I am a healthcare provider to persons living with long term/chronic illnesses');

        if (hcwResponded_ === undefined || !hcwResponded_) {
            hcwResponded_ = 0;
        }
        if (patientResponded_ === undefined && patientResponded2_ === undefined) {
            patientResponded_ = 0;
        } else if (patientResponded_ === undefined || patientResponded2_ === undefined) {
            patientResponded_ = patientResponded_ || patientResponded2_;
        } else if (patientResponded_ === undefined && patientResponded2_ === undefined) {
            patientResponded_ = patientResponded_ + patientResponded2_;
        }

        const allocation = getdistributionSampleAllocatedGrouping('state', Ip_facilityDistributionData, stateName);
        let patientRemaining_ = allocation.patient_size - patientResponded_;
        let hcwRemaining_ = allocation.hcw_size - (hcwResponded_);

        if (isNaN(patientRemaining_)) {
            patientRemaining_ = allocation.patient_size;
        }
        if (isNaN(hcwRemaining_)) {
            hcwRemaining_ = allocation.hcw_size;
        }
        if (patientRemaining_ < 0) {
            patientRemaining_ = 0;
        }
        if (hcwRemaining_ < 0) {
            hcwRemaining_ = 0;
        }

        patientResponded.push(patientResponded_);
        hcwResponded.push(hcwResponded_);
        patientRemaining.push(patientRemaining_);
        hcwRemaining.push(hcwRemaining_);
    }

    const series = [{
        name: 'patient remaining',
        data: patientRemaining,
        stack: 'patient'
    }, {
        name: 'patient responded',
        data: patientResponded,
        stack: 'patient'
    }, {
        name: 'Health Care Worker remaining',
        data: hcwRemaining,
        stack: 'Health Care Worker'
    }, {
        name: 'Health Care Worker responded',
        data: hcwResponded,
        stack: 'Health Care Worker'
    }];

    chartsToReturn.state_reporting_chart = build_side_by_side_column_chart("State Reporting Rate", "Number of respondents", xCategory, series, 400);
    //registerButton(theChart, "state-distribution-column", false);
    //registerButton(theChart, "state-distribution-bar", true);


    //genderChart
    let values = [];
    for (const v of data.genderGroup) {
        values.push({ name: v.key, y: v.count });
    }
    chartsToReturn.genderChart = BuildDonut("Sex group of Respondents", values);


    //marital status
    values = [];
    xCategory = [];
    for (const v of data.marital_statusGroup) {
        values.push({ name: v.key, y: v.count });
    }
    chartsToReturn.maritalChart = BuildPieChart("Marital status of Resondents", values);


    //employmentRate
    values = [];
    xCategory = [];
    for (const v of data.employmentRate) {
        xCategory.push(v.key);
        values.push(v.count);
    }
    chartsToReturn.employmentChart = build_Column_chart("Respondents Employment", "Number of respondents", xCategory, values, 400);


    //educationGroup
    values = [];
    xCategory = [];
    for (const v of data.educationGroup) {
        xCategory.push(v.key);
        values.push(v.count);
    }
    chartsToReturn.educationChart = build_Column_chart("Respondents Education", "Number of respondents", xCategory, values, 400);

    //age and sex group
    let malevalues = [];
    let femalevalues = [];
    for (const v of data.ageSexGroup) {
        if (v.key.gender === "Female") {
            femalevalues.push(v.count);
        } else if (v.key.gender === "Male") {
            malevalues.push(-v.count);
        }
    }
    const max = Math.max(Math.max.apply(Math, malevalues.map(Math.abs)), Math.max.apply(Math, femalevalues.map(Math.abs)));
    chartsToReturn.age_group_sex_chart = Build_Pos_Neg_Chart("Age and Sex group", data.ageGroup, [{ name: 'Male', data: malevalues }, { name: 'Female', data: femalevalues }], max);
            
    var stateCharts = getfacilityReportingRateCharts(thestateGroup, Ip_facilityDistributionData, data);
    chartsToReturn.stateChartArray = stateCharts;
    return chartsToReturn;
}

function getfacilityReportingRateCharts(thestateGroup, Ip_facilityDistributionData, data) {

    let stateChartArray = [];
    
    for (const theStateName of Object.keys(thestateGroup)) {
        //let theStateName = theState[0].state;
        let xCategory = thestateGroup[theStateName].map(function (y) { return y.hf_name });
        let patientResponded = []; let patientRemaining = []; let hcwResponded = []; let hcwRemaining = [];
        //var sites = [];

        for (const siteName of xCategory) {
            let patientResponded_ = getdistributionSampleCollectedGrouping('facility', data.facilityReportingRate, siteName, 'I am currently living with a long term/chronic illness');
            let patientResponded2_ = getdistributionSampleCollectedGrouping('facility', data.facilityReportingRate, siteName, 'I am a caregiver to one or more persons living with a long term/chronic illness');
            let hcwResponded_ = getdistributionSampleCollectedGrouping('facility', data.facilityReportingRate, siteName, 'I am a healthcare provider to persons living with long term/chronic illnesses');

            if (hcwResponded_ === undefined || !hcwResponded_) {
                hcwResponded_ = 0;
            }
            if (patientResponded_ === undefined && patientResponded2_ === undefined) {
                patientResponded_ = 0;
            } else if (patientResponded_ === undefined || patientResponded2_ === undefined) {
                patientResponded_ = patientResponded_ || patientResponded2_;
            } else if (patientResponded_ === undefined && patientResponded2_ === undefined) {
                patientResponded_ = patientResponded_ + patientResponded2_;
            }

            let allocation = getdistributionSampleAllocatedGrouping('facility', Ip_facilityDistributionData, siteName, theStateName);
            let patientRemaining_ = allocation.patient_size - patientResponded_;
            let hcwRemaining_ = allocation.hcw_size - (hcwResponded_);

            if (isNaN(patientRemaining_)) {
                patientRemaining_ = allocation.patient_size;
            }
            if (isNaN(hcwRemaining_)) {
                hcwRemaining_ = allocation.hcw_size;
            }
            if (patientRemaining_ < 0) {
                patientRemaining_ = 0;
            }
            if (hcwRemaining_ < 0) {
                hcwRemaining_ = 0;
            }

            patientResponded.push(patientResponded_);
            hcwResponded.push(hcwResponded_);
            patientRemaining.push(patientRemaining_);
            hcwRemaining.push(hcwRemaining_);
        }

        const series = [{
            name: 'patient remaining',
            data: patientRemaining,
            stack: 'patient'
        }, {
            name: 'patient responded',
            data: patientResponded,
            stack: 'patient'
        },
        {
            name: 'Health Care Worker remaining',
            data: hcwRemaining,
            stack: 'Health Care Worker'
        }, {
            name: 'Health Care Worker responded',
            data: hcwResponded,
            stack: 'Health Care Worker'
        }];

        stateChartArray.push({
            stateKey: theStateName.replaceAll(" ", "").toLowerCase(),
            theChart: build_side_by_side_column_chart(theStateName + " Respondents", "Number of respondents", xCategory, series, 400)
        });

        //stateChartArray.push(build_side_by_side_column_chart(theStateName + " Respondents", "Number of respondents", xCategory, series, 400));
    }

    return stateChartArray;
}


function getdistributionSampleCollectedGrouping(dataLevel, CollectionData, siteName, respondentTye) {
    let result;
    for (const item of CollectionData) {

        if (dataLevel === 'facility' && item.key.healthFacility_Name && item.key.healthFacility_Name.trim() === siteName.trim() && item.key.which_of_the_following_cat === respondentTye) {
            result = item.count;
        } else if (dataLevel === 'state' && item.key.state_Name && item.key.state_Name.trim() === siteName.trim() && item.key.which_of_the_following_cat === respondentTye) {
            result = item.count;
        }
    }
    return result;
}

function getdistributionSampleAllocatedGrouping(dataLevel, distributionData, siteName, theState) {
    let result = {
        'patient_size': 0,
        'hcw_size': 0
    };
    for (const item of distributionData) {

        if (dataLevel === 'facility' && item.hf_name && item.hf_name.trim() === siteName.trim() && item.state.trim() === theState.trim()) {

            result = {
                'patient_size': item.patient_size,
                'hcw_size': item.hcw_size
            }
        } else if (dataLevel === 'state' && item.state && item.state.trim() === siteName.trim()) {
            if (!isNaN(parseInt(item.patient_size)))
                result['patient_size'] += parseInt(item.patient_size);

            if (!isNaN(parseInt(item.hcw_size)))
                result['hcw_size'] += parseInt(item.hcw_size);
        }
    }
    return result;
}

//function registerButton(theChart, buttonId, inverted) {
//	document.getElementById(buttonId).addEventListener('click', () => {
//		theChart.update({
//			chart: {
//				height: inverted ? '600px' : '400px',
//				inverted: inverted,
//				polar: false
//			}
//		});
//	});
//}

function groupedByKey(array_items, key) {
    return array_items.reduce(function (r, a) {
        r[a[key]] = r[a[key]] || [];
        r[a[key]].push(a);
        return r;
    }, Object.create(null));
}