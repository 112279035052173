import React, { useState } from "react";
// reactstrap components
import {
    CardTitle,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody, Table
} from "reactstrap";


function ACEQuestionAnswer(props) {

    const [verticalTabs, setverticalTabs] = React.useState("facility")
    const allTabs = [
        { formname: "facility", name: "Facility Profile" },
        //{ formname: "facility_human_resources", name: "Facility HR" },
        { formname: "facility_staff", name: "Facility staff" },
        { formname: "other_facility_infrastructure", name: "Other facility infrastructure" },
        { formname: "me", name: "M&E" },
        { formname: "clinical_adult_care_treatment", name: "Clinical adult Care Treatment" },
        { formname: "pediatrics", name: "Pediatrics" },
        { formname: "pmtct", name: "PMTCT" },
        { formname: "pharmacy", name: "Pharmacy" },
        { formname: "laboratory", name: "Laboratory" },
        { formname: "tb", name: "TB TX" },
        { formname: "hts", name: "HTS" }
        //{ formname: "hts_sopguidelines", name: "HTS SOP" }
    ];
    const changeActiveTab = (e, tabState, tabName) => {
        e.preventDefault();
        setverticalTabs(tabName);
    };

    let formKeys = Object.keys(props.responseData);
    formKeys = formKeys ? formKeys.filter(s => s.includes('facility_staff')) : "";
    //let staffProps = formKeys && formKeys[0] ? props.responseData[formKeys[0]] : "";
    //let _staffProps = staffProps.map((columns) => columns.question);

    let staffProps = ['Service Unit', 'Name of staff', 'HCW Category', 'Designation', 'Phone Number', 'Email ', 'Received training in', 'Trained others ? ', 'Date of last training', 'full time or adhoc', 'Hours for community HIV']
    console.log(formKeys);
    //console.log(_staffProps);

    return (

        <Card>
            <CardHeader>
                <CardTitle tag="h3">Full response for <span style={{ color: '#e14eca' }}>{props.facilityName}</span></CardTitle>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md="4">
                        <Nav className="nav-pills-info flex-column" pills>
                            {
                                allTabs.map((prop, index) => (
                                    <NavItem key={prop.formname}>
                                        <NavLink
                                            data-toggle="tab"
                                            href="#pablo"
                                            className={verticalTabs === prop.formname ? "active" : ""}
                                            onClick={(e) =>
                                                changeActiveTab(e, "verticalTabs", prop.formname)
                                            }
                                            style={{ textAlign: 'left' }}>
                                            {prop.name}
                                        </NavLink>
                                    </NavItem>
                                ))
                            }
                        </Nav>
                    </Col>
                    <Col md="8">
                        <TabContent activeTab={verticalTabs}>
                            {

                                allTabs.map((prop, index) => (
                                    <TabPane tabId={prop.formname} key={prop.formname}>
                                        <Table className="table">
                                            <tbody className="text-left">
                                                {
                                                    props.responseData && prop.formname.includes("facility_staff") ?

                                                        <>
                                                            <tr className="text-center">
                                                                <td>Unit</td>
                                                                <td>Name</td>
                                                                <td>HCW Category</td>
                                                                <td>Designation</td>
                                                                <td>Contact </td>
                                                                <td>Traning </td>
                                                                <td>Date of Last Training </td>
                                                                <td>Availabe Hours for comm. HIV </td>
                                                            </tr>

                                                            {
                                                                formKeys.map((aform, index0) =>
                                                                    <tr key={index0} className="text-center">
                                                                        <td style={{ color: '#e14eca' }}>
                                                                            {props.responseData[aform][1]['answer']}
                                                                        </td>
                                                                        <td style={{ color: '#e14eca' }}>
                                                                            {props.responseData[aform][2]['answer']}
                                                                        </td>
                                                                        <td>
                                                                            <p style={{ color: '#e14eca' }}>
                                                                                {props.responseData[aform][3]['answer']}
                                                                            </p>                                                                            
                                                                            {props.responseData[aform][10]['answer']}
                                                                        </td>
                                                                        <td style={{ color: '#e14eca' }}>
                                                                            {props.responseData[aform][4]['answer']}
                                                                        </td>
                                                                        <td>
                                                                            <p style={{ color: '#e14eca' }}>
                                                                                {props.responseData[aform][5]['answer']}
                                                                            </p>
                                                                            {" "}
                                                                            {props.responseData[aform][6]['answer']}
                                                                        </td>
                                                                        <td>
                                                                            <p style={{ color: '#e14eca' }}>
                                                                                {props.responseData[aform][7]['answer']}
                                                                            </p>
                                                                            {" "}
                                                                            {props.responseData[aform][8]['answer']}
                                                                        </td>
                                                                        <td style={{ color: '#e14eca' }}>
                                                                            {props.responseData[aform][9]['answer']}
                                                                        </td>
                                                                        <td style={{ color: '#e14eca' }}>
                                                                            {props.responseData[aform][11]['answer']}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }

                                                        </>
                                                        :
                                                        (
                                                            props.responseData && props.responseData[prop.formname] ?
                                                                props.responseData[prop.formname].map((responseData, index2) =>
                                                                (
                                                                    <tr key={index2}>
                                                                        <td>
                                                                            <p className="title questionColor">{responseData.question}</p>
                                                                            <p style={{ color: '#e14eca' }}>
                                                                                {responseData.answer}
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                                :
                                                                <tr>
                                                                    <td>
                                                                        <p className="text-muted">form is empty</p>
                                                                    </td>
                                                                </tr>
                                                        )
                                                }
                                            </tbody>
                                        </Table>
                                    </TabPane>

                                ))
                            }
                        </TabContent>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}
export default ACEQuestionAnswer