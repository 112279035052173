import React from 'react';
import classNames from "classnames";
import {
    Card, CardBody, CardHeader, CardTitle, Spinner, ButtonGroup, Button, Row, Col
} from "reactstrap"
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { BuildSmartLockerCharts } from '../../assets/js/BuildSmartLockerCharts';
import AuthorizedFetch from '../../services/AuthenticatedFetch';

function SmartLockerDashboard(props) {
    const instanceURL = `${process.env.REACT_APP_SURVEY_BASE_URL}`
    const [pageSubcategories, setPageSubcategories] = React.useState("Reporting-Rate-tab");
    const [Ip_facilityDistributionData, setIp_facilityDistributionData] = React.useState(null);
    const [surveyData, setsurveyData] = React.useState(null);
    const [SmartLockerCharts, setSmartLockerCharts] = React.useState({});
    const [pageproperties, setPageproperties] = React.useState({ loading: true });
    //Reporting-Rate-tab, sample-distribution-tab, Demography-tab

    console.log("instanceURL >= " + instanceURL);

    const fetchIp_facilityDistributionData = async () => {

        const response = await fetch(`${instanceURL}/SmartLocker/GetIp_facilityDistributionData`, {
            method: `GET`,
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("user.accessToken")}`
            } });
        const theData = await response.json();
        setIp_facilityDistributionData(theData);
        console.log(theData);
        

        //const response = await AuthorizedFetch(`${instanceURL}/SmartLocker/GetIp_facilityDistributionData`, 'GET', null, true);
        //    //await fetch(`${instanceURL}/SmartLocker/GetIp_facilityDistributionData`);
        //const theData = await response.json();
        //setIp_facilityDistributionData(theData);
        //console.log(theData);
    };
    const fetchSurveyData = async () => {
        const response = await fetch(`${instanceURL}/SmartLocker/GetData`, {
            method: `GET`,
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("user.accessToken")}`
            }
        });
        const theData = await response.json();
        setsurveyData(theData);
        console.log(theData);
        //const response = await AuthorizedFetch(`${instanceURL}/SmartLocker/GetData`, 'GET', null, true);
        //    //await fetch(`${instanceURL}/SmartLocker/GetData`);
        //const theData = await response.json();
        //setsurveyData(theData);
        //console.log(theData);
    };

    React.useEffect(() => {
        fetchIp_facilityDistributionData();
        fetchSurveyData();
    }, []);

    React.useEffect(() => {
        if (surveyData && Ip_facilityDistributionData) {
            setSmartLockerCharts(
                BuildSmartLockerCharts(surveyData, Ip_facilityDistributionData)
                //BuildSmartLockerCharts(surveyData, Ip_facilityDistributionData)
            );
            setPageproperties({ loading: false });
            console.log("redrawn charts");
        }
        else {
            console.log("this is called on load");
        }
    }, [surveyData, Ip_facilityDistributionData]);


    return (
        <>
            <div className="landingPageContent">
                <Row>
                    <Col xs="12">
                        <Card className="card-chart">
                            <CardHeader>
                                <Row>
                                    <Col className="text-left" sm="6">
                                        <h5 className="card-category">Smart Locker Survey</h5>
                                        <CardTitle tag="h2"> Monitoring Dashboard</CardTitle>
                                    </Col>
                                    <Col sm="6">
                                        <ButtonGroup
                                            className="btn-group-toggle float-right"
                                            data-toggle="buttons">
                                            <Button
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: pageSubcategories === "Reporting-Rate-tab",
                                                })}
                                                color="info"
                                                id="0"
                                                size="sm"
                                                onClick={() => setPageSubcategories("Reporting-Rate-tab")}>
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Reporting Rate
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-single-02" />
                                                </span>
                                            </Button>
                                            <Button
                                                color="info"
                                                id="1"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: pageSubcategories === "sample-distribution-tab",
                                                })}
                                                onClick={() => setPageSubcategories("sample-distribution-tab")}>
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Sample Distribution
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-gift-2" />
                                                </span>
                                            </Button>
                                            <Button
                                                color="info"
                                                id="2"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: pageSubcategories === "Demography-tab",
                                                })}
                                                onClick={() => setPageSubcategories("Demography-tab")}>
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Demography
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-tap-02" />
                                                </span>
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <div className="">
                                    <div className={pageSubcategories === "Reporting-Rate-tab" ? 'show' : 'hidden'}>
                                        {
                                            pageproperties.loading ?
                                                <div style={{ marginLeft: '40%' }}>
                                                    <img
                                                        alt="..." style={{ maxWidth: '30%' }}
                                                        src={require("assets/img/loader2.gif").default}
                                                    />
                                                </div>
                                                :
                                                <div>
                                                    <HighchartsReact key={"state_reporting_chart"} highcharts={Highcharts} options={SmartLockerCharts.state_reporting_chart} />
                                                    <Button color="success" className="btn-round" size="sm">column</Button>
                                                    <Button color="info" className="btn-simple btn-round" size="sm">bar</Button>
                                                </div>
                                        }
                                    </div>
                                    <div className={pageSubcategories === "sample-distribution-tab" ? 'show' : 'hidden'}>
                                        {
                                            pageproperties.loading ?
                                                <div style={{ marginLeft: '40%' }}>
                                                    <img
                                                        alt="..." style={{ maxWidth: '30%' }}
                                                        src={require("assets/img/loader2.gif").default}
                                                    />
                                                </div>
                                                :
                                                SmartLockerCharts.stateChartArray.map((stateFacilittItem, index) =>
                                                    <div key={index}>
                                                        <HighchartsReact  highcharts={Highcharts} options={stateFacilittItem.theChart} />
                                                        <Button color="success" className="btn-round" size="sm">column</Button>
                                                        <Button color="info" className="btn-simple btn-round" size="sm">bar</Button>
                                                    </div>
                                                )
                                        }
                                    </div>
                                    <div className={pageSubcategories === "Demography-tab" ? 'show' : 'hidden'}>
                                        {
                                            pageproperties.loading ?
                                                <div style={{ marginLeft: '40%' }}>
                                                    <img
                                                        alt="..." style={{ maxWidth: '30%' }}
                                                        src={require("assets/img/loader2.gif").default}
                                                    />
                                                </div>
                                                :
                                                <div>
                                                    <Row>
                                                        <Col lg="6">
                                                            <HighchartsReact key="genderChart" highcharts={Highcharts} options={SmartLockerCharts.genderChart} />
                                                        </Col>
                                                        <Col lg="6">
                                                            <HighchartsReact key="maritalChart" highcharts={Highcharts} options={SmartLockerCharts.maritalChart} />
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <Row>
                                                        <Col lg="12">
                                                            <HighchartsReact key="age_group_sex" highcharts={Highcharts} options={SmartLockerCharts.age_group_sex_chart} />
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <Row>
                                                        <Col lg="6">
                                                            <HighchartsReact key="educationChart" highcharts={Highcharts} options={SmartLockerCharts.educationChart} />
                                                        </Col>
                                                        <Col lg="6">
                                                            <HighchartsReact key="employmentChart" highcharts={Highcharts} options={SmartLockerCharts.employmentChart} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default SmartLockerDashboard;