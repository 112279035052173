


export default async function AuthorizedFetch(url, method, data, authenticated) {

    const token = localStorage.getItem("user.accessToken") ? localStorage.getItem("user.accessToken") : "";
    if (authenticated) {
        const response = await fetch(url, {
            method: method,
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: data? JSON.stringify(data) : null
        });
        return await response.json();
    } else {

        const response = await fetch(url, {
            method: method,
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });
        return response.json();
    }   
}