import AuthorizedFetch from './AuthenticatedFetch';

const instanceURL = `${process.env.REACT_APP_SURVEY_BASE_URL}`

async function register (userInfo) {
    let registerUrl = `${instanceURL}/User/Register`;
    const responseData = await AuthorizedFetch(registerUrl, 'POST', userInfo, true);
    return responseData;
};

async function login (username, password) {
    let siginUrl = `${instanceURL}/User/Authenticate`;
    let responseData = await AuthorizedFetch(siginUrl, 'Post', {
        username,
        password,
    }, false);

    if (responseData.token) {
        localStorage.setItem("user.accessToken", responseData.token);
        localStorage.setItem("user", JSON.stringify(responseData));
    }
    return responseData;
};

const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("user.accessToken");
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

export default {
    register : register,
    login : login,
    logout : logout,
    getCurrentUser : getCurrentUser,
};